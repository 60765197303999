import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.secondary.main,
//     borderColor: '#79b751',
//     '& > *': {
//       margin: theme.spacing(3),
//     }
//   }
// }));

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.secondary.main,
    borderColor: '#79b751',
    paddingTop: "30px",
    paddingBottom: "30px"
  }
}));

const Recommendation = (props) => {
  
    const classes = useStyles();
    // const mockData = MockReq.reqData;
    // console.log(mockData);
    // console.log(props.userScore);

    const [serviceRecommendation, setServiceRecommendation] = useState({});

    const reqData = props.scores;
    //reqData.serviceId = "525d3d66-fbe1-43a9-b042-1630e48f0ac2";
    reqData.serviceId = props.service._id;
    reqData.useRanking = true;
    //console.log("User scores/ratings ", reqData);

    const getRecommendation = e => {
        //const url = "https://uk1w8r8hhk.execute-api.us-west-2.amazonaws.com/" + process.env.REACT_APP_API_ENV + "/SS-RecommendationEngine";
        const url = "https://mjjxjbnitc.execute-api.us-west-2.amazonaws.com/" + process.env.REACT_APP_API_ENV + "/simplesasquatch-getRecommendation";
        Axios.post(url, reqData)
            .then(function (response) {
                //console.log(response);
                //const res = JSON.parse(response.data.body);
                const res = response.data;
                const scores = res.scores;
                const recommendedService = scores[0].serviceName;
                const totalScore = scores[0].totalScore;
                //console.log("Recommendation scores ", scores);           
                dataLayer.push({
                  'event': 'quizEvent',
                  'eventCategory': 'Service Recommendation',
                  'eventAction': recommendedService,
                  'eventLabel': totalScore.toString()
                })
                setServiceRecommendation(res);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
      getRecommendation();
    }, [])

    const handleAffiliateLinkClick = (e) => {
      //console.log(serviceRecommendation.scores[0].serviceUrl);
      dataLayer.push({
        'event': 'quizEvent',
        'eventCategory': 'Service Recommendation Click',
        'eventAction': serviceRecommendation.scores[0].serviceName
      })
    }

  return (
    (serviceRecommendation.scores) ?
    <div className={classes.root}>
      <Grid container spacing={2} direction="row" justify="center">
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary" gutterBottom>Recommendation</Typography>
        </Grid>
        <Grid item xs={12}>
          <img src={"/images/" + serviceRecommendation.scores[0].logoImg} width="60%" />
        </Grid>
        <Grid item xs={10}>
        <Typography variant="subtitle1" gutterBottom>
              {serviceRecommendation.scores[0].reviewConclusion}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant="contained" color="primary" href={serviceRecommendation.scores[0].reviewUrl} width="30%">Check Out Our Research</Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <Button variant="contained" color="primary" onClick={ (e) => handleAffiliateLinkClick(e) } target="_blank" width="30%">Sign Me Up!</Button> */}
          <Button variant="contained" color="primary" onClick={ (e) => handleAffiliateLinkClick(e) } href={serviceRecommendation.scores[0].serviceUrl} target="_blank" width="30%">Sign Me Up!</Button>
        </Grid>
      </Grid>
    </div>
    :
    <LinearProgress />
  )
}

export default Recommendation;