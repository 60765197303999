import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TagManager from 'react-gtm-module'

import Wizard from "./components/Wizard";
import SelectService from "./components/SelectService";


import ButtonAppBar from "./components/AppBar";
import Chip from '@material-ui/core/Chip';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";

import Axios from 'axios';

const tagManagerArgs = {
  gtmId: 'GTM-NT9SMRL'
}

TagManager.initialize(tagManagerArgs)


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    marginBottom: "40px",
    marginTop: "40px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  card: {
    maxWidth: 345,
    float: "left",
    marginRight: "16px"
  },
  media: {
    height: 140
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://simplesasquatch.com">
        SimpleSasquatch
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function App() {
  
  const classes = useStyles();

  const urlParams = new URLSearchParams(window.location.search);
  const serviceId = urlParams.get('serviceId');
  
  
  const [service, setService] = useState();

  const getServiceById = (serviceId) => {
    //const url = "https://uk1w8r8hhk.execute-api.us-west-2.amazonaws.com/" + process.env.REACT_APP_API_ENV + "/SS-RecommendationEngine-GetServices" + "/?serviceId=" + serviceId;
    const url = "https://mjjxjbnitc.execute-api.us-west-2.amazonaws.com/" + process.env.REACT_APP_API_ENV + "/simplesasquatch-getServices" + "?serviceId=" + serviceId;
    Axios.get(url)
      .then(function (response) {
          //console.log(response);
          setService(response.data.Item);
          dataLayer.push({
              'event': 'quizEvent',
              'eventCategory': 'Service Selected',
              'eventAction': response.data.Item.serviceName
            })
      })
      .catch(function (error) {
          console.log(error);
      });
  }

  const resetServices = () => {
    console.log("Reset scores");
    setService({});
  }

  useEffect(() => {
    if (serviceId){
      getServiceById(serviceId);
    }
  }, [setService]);

  return (
    <div>
      {/* <ButtonAppBar resetServices={resetServices} /> */}

      { (!service && !serviceId) ?
        <SelectService setService={setService} />
      : null
      }

      {
        (service && service._id) ?
        <Container maxWidth="md" className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h1" gutterBottom>
                {service.serviceName} <br></br>{service.topics.map(topic => <Chip label={topic} key={topic} color="secondary" />)}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {service.description}
              </Typography>
              <Wizard service={service}></Wizard>
            </Grid>
          </Grid>
        </Container>
        : null
      }
      
      {/* <Container maxWidth="lg" className={classes.container}>
        <Copyright></Copyright>
      </Container> */}
    </div>
  );
}
