const data = {
    services: [
        {	
            "_id": "525d3d66-fbe1-43a9-b042-1630e48f0ac2",
            "topics": [
                "hosting", "websites"
            ],
            "serviceCategory": "Building an online business",
            "serviceName": "Website Builders",
            "criteria": [
                {
                    "_id": 123,
                    "questionText": "How important is cost?",
                    "shortText": "cost"
                },
                {
                    "_id": 245,
                    "questionText": "How important is ease of use?",
                    "shortText": "ease"
                },
                {
                    "_id": 345,
                    "questionText": "How important are templates and photos?",
                    "shortText": "templates and photos",
                    "helpText": "A template is a pre-built website design."
                },
                {
                    "_id": 456,
                    "questionText": "How important is the ability to customize your site?",
                    "shortText": "customization"
                },
                {
                    "_id": 567,
                    "questionText": "How important is technical functionality?",
                    "shortText": "technical functionality"
                },
                {
                    "_id": 890,
                    "questionText": "How important is business functionality?",
                    "shortText": "business functionality"
                },
                {
                    "_id": 654,
                    "questionText": "How important is speed, reliability and security?",
                    "shortText": "speed, reliability and security"
                },
                {
                    "_id": 987,
                    "questionText": "How important is support?",
                    "shortText": "support"
                }
            ],
            "serviceScores" : [
                {
                    "name": "weebly",
                    "logoImg": "weebly-logo-png-transparent.png",
                    "scores": { 
                        "123": 5, 
                        "245": 5, 
                        "345": 3,
                        "456": 3,  
                        "567": 5,
                        "890": 5, 
                        "654": 5,
                        "987": 4
                    },
                    "affiliateScore": 7
                },
                {
                    "name": "wordpress",
                    "logoImg": "wordpress-logo.png",
                    "scores": { 
                        "123": 4, 
                        "245": 2, 
                        "345": 4,
                        "456": 5,  
                        "567": 5,
                        "890": 5, 
                        "654": 3,
                        "987": 4
                    },
                    "affiliateScore": 7
                },
                {
                    "name": "godaddy",
                    "logoImg": "godaddy-logo.png",
                    "scores": {
                        "123": 5, 
                        "245": 2, 
                        "345": 4,
                        "456": 2,  
                        "567": 3,
                        "890": 4, 
                        "654": 4,
                        "987": 3 
                    },
                    "affiliateScore": 7
                },
                {
                    "name": "wix",
                    "logoImg": "wix-logo.png",
                    "scores": { 
                        "123": 5, 
                        "245": 5, 
                        "345": 5,
                        "456": 4,  
                        "567": 5,
                        "890": 4, 
                        "654": 3,
                        "987": 3
                    },
                    "affiliateScore": 7
                },
                {
                    "name": "squarespace",
                    "logoImg": "squarespace-logo.png",
                    "scores": { 
                        "123": 3, 
                        "245": 4, 
                        "345": 5,
                        "456": 3,  
                        "567": 4.5,
                        "890": 5, 
                        "654": 4,
                        "987": 5
                    },
                    "affiliateScore": 7
                }
            ],
            "shortName": "website builder",
            "responseType": "one-to-five",
            "description": "A website builder is an interactive tool for designing, building, and publishing your digital content." ,
            "helpText": "Help text here",
            "imageSrc": "/images/website-builder.jpg",
            "stage": "live"
        },
        {
            "_id": "3f4c40ec-edec-4049-99c0-296c07e2e04d",
            "topics": [
                "hosting", "websites"
            ],
            "serviceCategory": "Building an online business",
            "serviceName": "Website Hosting",
            "criteria": [
                {
                    "_id": 123,
                    "questionText": "How important is cost?",
                    "shortText": "cost"
                },
                {
                    "_id": 245,
                    "questionText": "How important is hosting privacy?",
                    "shortText": "privacy"
                },
                {
                    "_id": 345,
                    "questionText": "How important are extra features?",
                    "shortText": "features",
                    "helpText": "Extra features might include things like eCommerce functionality or video streaming."
                },
                {
                    "_id": 456,
                    "questionText": "How important is the ability to transfer your site?",
                    "shortText": "transferability"
                },
                {
                    "_id": 567,
                    "questionText": "How important is support?",
                    "shortText": "support"
                }
            ],
            "shortName": "",
            "responseType": "",
            "description": "A host is a service provider you'll need to store and serve your website to the public.",
            "helpText": "Help text here",
            "imageSrc": "/images/web-hosting.jpg",
            "stage": "live"
        },
        {
            "_id": "26bcd31f-387a-446f-9068-737ca92608a3",
            "topics": [
                "hosting", "websites"
            ],
            "serviceCategory": "Building an online business",
            "serviceName": "SEO Tools",
            "criteria": [
                {
                    "_id": 123,
                    "questionText": "How important is cost?",
                    "shortText": "cost"
                },
                {
                    "_id": 245,
                    "questionText": "How important is hosting privacy?",
                    "shortText": "privacy"
                },
                {
                    "_id": 345,
                    "questionText": "How important are extra features?",
                    "shortText": "features",
                    "helpText": "Extra features might include things like eCommerce functionality or video streaming."
                },
                {
                    "_id": 456,
                    "questionText": "How important is the ability to transfer your site?",
                    "shortText": "transferability"
                },
                {
                    "_id": 567,
                    "questionText": "How important is support?",
                    "shortText": "support"
                }
            ],
            "shortName": "",
            "responseType": "",
            "description": "Search engine optimiztion will be required to make sure people can find your content on sites like Google.",
            "helpText": "Help text here",
            "imageSrc": "/images/seo-tools.jpg",
            "stage": "live"
        }
    ]    
}

const scores = {
    serviceScoresId: "525d3d66-fbe1-43a9-b042-1630e48f0ac2",
    websiteBuilders: [
        {
            name: 'weebly',
            serviceScores: { 
                cost: 5, 
                ease: 5, 
                templates_photos: 3,
                customization: 3,  
                functionality: 5,
                business_functionality: 5, 
                speed_reliability_security: 5,
                support: 4
            },
            affiliateScore: 7
        },
        {
            name: 'wordpress',
            serviceScores: { 
                cost: 4, 
                ease: 2, 
                templates_photos: 4,
                customization: 5,  
                functionality: 5,
                business_functionality: 5, 
                speed_reliability_security: 3,
                support: 4
            },
            affiliateScore: 7
        },
        {
            name: 'godaddy',
            serviceScores: { 
                cost: 5, 
                ease: 2, 
                templates_photos: 4,
                customization: 2,
                functionality: 3,
                business_functionality: 4, 
                speed_reliability_security: 4,
                support: 3
            },
            affiliateScore: 7
        },
        {
            name: 'wix',
            serviceScores: { 
                cost: 5, 
                ease: 5, 
                templates_photos: 5,
                customization: 4,
                functionality: 5,
                business_functionality: 4,
                speed_reliability_security: 3,
                support: 3
            },
            affiliateScore: 7
        },
        {
            name: 'squarespace',
            serviceScores: { 
                cost: 3, 
                ease: 4, 
                templates_photos: 5,
                customization: 3,
                functionality: 4.5,
                business_functionality: 5,
                speed_reliability_security: 4,
                support: 5
            },
            affiliateScore: 7
        }
    ]  
}

const registrars = [
    {
        name: 'godaddy',
        serviceScores: { cost: -3, privacy: 3, features: 3, transferability: 3, tlds: 4 },
        affiliateScore: 7
    },
    {
        name: 'namecheap',
        serviceScores: { cost: -5, privacy: 5, features: 5, transferability: 5, tlds: 5 },
        affiliateScore: 5
    },
    {
        name: 'domain.com',
        serviceScores: { cost: -2, privacy: 4, features: 5, transferability: 5, tlds: 3 },
        affiliateScore: 6
    },
    {
        name: 'hover',
        serviceScores: { cost: -4, privacy: 1, features: 5, transferability: 1, tlds: 1 },
        affiliateScore: 4
    },
    {
        name: 'name.com',
        serviceScores: { cost: -5, privacy: 5, features: 3, transferability: 2, tlds: 2 },
        affiliateScore: 7
    }
]

const hosts = [
    { name: 'godaddy', plans: 3, privacy: 3, features: 3, transferability: 3, support: 4 },
    { name: 'namecheap', plans: 5, privacy: 5, features: 5, transferability: 5, support: 5 },
    { name: 'hostgator', plans: 2, privacy: 4, features: 5, transferability: 5, support: 3 },
    { name: '1&1', plans: 4, privacy: 1, features: 5, transferability: 1, support: 1 },
    { name: 'hostwinds', plans: 5, privacy: 5, features: 3, transferability: 2, support: 2 },
    { name: 'dreamhost', plans: 5, privacy: 3, features: 4, transferability: 3, support: 1 },
    { name: 'beyond hosting', plans: 5, privacy: 2, features: 3, transferability: 4, support: 5 },
]


const postmanEx = {
    "scores": [
      {
        "questionText": "How important is ease of use?",
        "shortText": "Ease of use",
        "_id": 245,
        "userScore": "4",
        "userRanking": 1
      },
      {
        "questionText": "How important is cost?",
        "shortText": "Cost",
        "_id": 123,
        "userScore": "3",
        "userRanking": 2
      },
      {
        "helpText": "A template is a pre-built website design that you can use for blogging, ecommerce, artist portfolios, etc.",
        "_id": 345,
        "questionText": "How important are templates and photos?",
        "shortText": "Templates and photos",
        "userScore": "3",
        "userRanking": 3
      },
      {
        "helpText": "Customization is being able to make your website your own by adjusting template/site structure and adding features; it can also mean the ability to make changes to the website background code.",
        "_id": 456,
        "questionText": "How important is the ability to customize your site?",
        "shortText": "Customization",
        "userScore": "5",
        "userRanking": 4
      },
      {
        "helpText": "Technical functionality is the ability to do more advanced coding on your site, like editing code, adding widgets, apps, and other developer tools.",
        "_id": 567,
        "questionText": "How important is technical functionality?",
        "shortText": "Technical functionality",
        "userScore": "4",
        "userRanking": 5
      },
      {
        "helpText": "Business functionality is gearing your site towards building a business with tools for e-commerce, collecting payments, and tracking orders.",
        "_id": 890,
        "questionText": "How important is business functionality?",
        "shortText": "Business functionality",
        "userScore": "3",
        "userRanking": 6
      },
      {
        "questionText": "How important is speed, reliability and security?",
        "shortText": "Speed, reliability and security",
        "_id": 654,
        "userScore": "5",
        "userRanking": 7
      },
      {
        "questionText": "How important is support?",
        "shortText": "Support",
        "_id": 987,
        "userScore": "4",
        "userRanking": 8
      }
    ],
    "serviceId": "525d3d66-fbe1-43a9-b042-1630e48f0ac2",
    "useRanking": true
  };

module.exports = { data, registrars, hosts };
