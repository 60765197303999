import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const Montserrat = {
  fontFamily: '"Montserrat", san-serif',
  src: 'url(https://fonts.googleapis.com/css?family=Montserrat&display=swap)',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400
}

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    // primary: {
    //   light: '#757ce8',
    //   main: '#3f50b5',
    //   dark: '#002884',
    //   contrastText: '#fff',
    // },
    primary: {
      main: '#303C6C',
    },
    // secondary: {
    //   light: '#ff7961',
    //   main: '#f44336',
    //   dark: '#ba000d',
    //   contrastText: '#000',
    // },
    secondary: {
      main: '#F4976C',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [Montserrat],
      },
    },
  },
});

export default theme;
