import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Data from './mocks/data';

import RankSort from "./RankSort";
import Recommendation from './Recommendation';


import HelpText from './HelpText';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  }
  // bullet: {
  //   display: 'inline-block',
  //   margin: '0 2px',
  //   transform: 'scale(0.8)',
  // },
  // title: {
  //   fontSize: 14,
  // }
}));

const Service = (props) => {

    const classes = useStyles();

    const service = props.service;
    const activeStep = props.activeStep;
    const numberOfSteps = service.criteria.length;

    const initialState = service.criteria;
    const [scores, setScores] = useState({scores: initialState});

    useEffect(() => {
    }, []);

    const handleClick = e => {
      e.preventDefault();
      setScores( () => {
        //console.log("Setting score...");
        const updatedScores = {...scores};
        updatedScores.scores[activeStep].userScore = e.currentTarget.value;
        return updatedScores;
      });
      props.handleNext();
    }

    const resetScores = () => {
      console.log("Reset scores");
      setScores({scores: initialState});
    }

  return (
      <Grid container spacing={2} justify="center">
        { (activeStep < numberOfSteps) ? 
          <>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              {service.criteria[activeStep].questionText}
            </Typography>
            { (service.criteria[activeStep].helpText) ? <HelpText message={service.criteria[activeStep].helpText} /> : null }
          </Grid>
          <Grid item xs={11} sm={6} md={6} lg={5} xl={7}>
              <Box display="flex" justifyContent="center">
                <Box>
                  Not important
                </Box>
                <Box style={{flex: "auto"}}></Box>
                <Box>
                  Very important
                </Box>
              </Box>
          </Grid>
          <Grid item xs={12}>
          <ButtonGroup color="primary" aria-label="outlined secondary button group">
            {/* <div className={classes.root}> */}
                <Button
                value="1"
                variant="contained"
                size="large"
                onClick={ (e) => handleClick(e) }>1</Button>
                <Button 
                size="large"
                value="2" 
                variant="contained"
                onClick={ (e) => handleClick(e) }>2</Button>
                <Button 
                size="large"
                value="3" 
                variant="contained"
                onClick={ (e) => handleClick(e) }>3</Button>
                <Button
                size="large"
                value="4" 
                variant="contained"
                onClick={ (e) => handleClick(e) }>4</Button>
                <Button 
                size="large"
                value="5" 
                variant="contained"
                onClick={ (e) => handleClick(e) }>5</Button>
            {/* </div> */}
            </ButtonGroup>
          </Grid>
          </>
          : null }
          { (activeStep === numberOfSteps ) ?
            <Grid item xs={12}>
              <Typography color="textSecondary" gutterBottom>Place these items in order of most important to least important</Typography>
              <RankSort scores={scores} service={service} setScores={setScores}></RankSort>
          </Grid>
          : null }
          { (activeStep === numberOfSteps + 1 ) ?
            <Grid item xs={12}>
              <Recommendation scores={scores} service={service} />
          </Grid>
          : null }
          <Grid item xs={12}>
            <Divider variant="middle" />
            <Button color="inherit" onClick={props.resetSteps}>Reset Scores</Button> 
          </Grid>
        </Grid>
  )
}

export default Service;