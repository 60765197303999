import React, {useState, useEffect} from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import './rank.css';

const itemStyle = {
  height: '59px'
}
const SortableItem = SortableElement(({value}) => <div className="Showcase__style__item Showcase__style__stylizedItem" style={itemStyle}><div className="Showcase__style__handle"></div>{value.shortText}</div>);

const SortableList = SortableContainer(({items}) => {
  //console.log(items);
  return (
    <div className="Showcase__Content__wrapper">
      <div className="Showcase__style__list Showcase__style__stylizedList">
        { 
          items.map((value, index) => {
              return <SortableItem key={value._id} index={index} value={value} />
          })
        }
      </div>
    </div>
  );
});

const arrayMove = (array, from, to) => {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  array.forEach( (item, index) => {
      item.userRanking = index + 1;
  })
  return array;
}

const Ranksort = (props) => {

  const initialSort = () => {
    let rank = props.scores.scores.map((item, index) => {
      let newItem = {...item};
      newItem.userRanking = index + 1;
      return newItem;
    });
    props.setScores({scores: rank});
  }

  //const [items, setItems] = useState(initialSort);

  //console.log(initialSort);

  useEffect(() => {
    initialSort();
  }, []);

  const onSortEnd = ({oldIndex, newIndex}) => {
      const updatedSort = arrayMove(props.scores.scores, oldIndex, newIndex);
      //console.log(updatedSort);
      props.setScores({scores: updatedSort});
  };
  
  return <SortableList items={props.scores.scores} onSortEnd={onSortEnd} />;

}

export default Ranksort;