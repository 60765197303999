import React, {useState, useEffect} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Data from './mocks/data';
import Axios from 'axios';

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from '@material-ui/core/LinearProgress';



const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    container: {
      marginBottom: "40px",
      marginTop: "40px"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.secondary.main
    },
    card: {
      maxWidth: 345,
      float: "left",
      marginRight: "16px"
    },
    media: {
      height: 270
    },
    title: {
      fontSize: 14
    },
    pos: {
      marginBottom: 12
    }
  }));


// let allServices = [];


export default function SelectService(props) {
  const classes = useStyles();

  const getAllServices = () => {
    //const url = "https://uk1w8r8hhk.execute-api.us-west-2.amazonaws.com/" + process.env.REACT_APP_API_ENV + "/SS-RecommendationEngine-GetServices";
    const url = "https://mjjxjbnitc.execute-api.us-west-2.amazonaws.com/" + process.env.REACT_APP_API_ENV + "/simplesasquatch-getServices";
    Axios.get(url)
      .then(function (response) {
          //console.log(response);
          setAllServices(response.data.Items);
      })
      .catch(function (error) {
          console.log(error);
      });
  }

  const handleSelectService = (e, service) => {
    const selectedService = allServices.find( (item) => {
      return (item._id == service._id) ? item : false;
    });

    props.setService(selectedService);
  };

  const [allServices, setAllServices] = useState([]);
  
  //run only once
  useEffect(() => {
    getAllServices();
  }, []);

  return (
    <Container maxWidth="lg" className={classes.container}>
      {/* <Typography variant="h5" gutterBottom>
        What can we help you with today?
      </Typography> */}
      {
        (allServices.length == 0) ? <LinearProgress /> : false
      }
      <Grid container spacing={4}>
          {
            allServices.map(service => {
              return (
              <Grid item xs={12} sm={6} md={4} key={service._id}>
                <Card>
                <CardMedia
                    className={classes.media}
                    image={service.imageSrc}
                    title={service.serviceName}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {service.serviceName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {service.description}
                    </Typography>
                  </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={e => handleSelectService(e, service)}
                  >
                    Get Started
                  </Button>
                </CardActions>
              </Card>
              </Grid>
              )
            })
          }
      </Grid>
    </Container>
  );
}
